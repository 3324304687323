import React from "react";

export const History = (props) => {
  return (
    <>
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img
                src="img/canes/canehistory.jpg"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2>Cane Corso History</h2>
                <p style={{ textAlign: "justify" }}>
                  {props.data ? props.data.paragraph : "loading..."}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <p style={{ textAlign: "justify" }}>
                  {props.data ? props.data.paragraph2 : "loading..."}
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              {" "}
              <img 
                src="img/canes/canehistory-2.jpg"
                className="img-responsive"
                alt=""
                style={{marginTop:"4em"}}
              />{" "}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img
                src="img/canes/canehistory-3.jpg"
                className="img-responsive"
                alt=""
              />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <p style={{ textAlign: "justify" }}>
                  {props.data ? props.data.paragraph3 : "loading..."}
                </p>
              </div>
            </div>
          </div>
          <div>
            <h2>Resources</h2>
            <ul>
              <li>
                Suspicious:{" "}<br/>
                <a href="https://www.britannica.com/dictionary/suspicious">
                  (https://www.britannica.com/dictionary/suspicious)
                </a>
              </li>
              <li>
                {" "}
                Animal:<br/>
                <a href="https://www.britannica.com/animal/animal">
                  (https://www.britannica.com/animal/animal)
                </a>{" "}
              </li>
              <li>
                Canine:{" "}<br/>
                <a href="https://www.britannica.com/animal/canine">
                  (https://www.britannica.com/animal/canine)
                </a>
              </li>
              <li>
                {" "}
                Hip Dysplasia:<br/>
                <a href="https://www.britannica.com/science/hip-dysplasia">
                  (https://www.britannica.com/science/hip-dysplasia)
                </a>
              </li>
              <li>
                Provoked:{" "}<br/>
                <a href="https://www.britannica.com/dictionary/provoked">
                  (https://www.britannica.com/dictionary/provoked)
                </a>
              </li>
              <li>
                {" "}
                Energy State:<br/>
                <a href="https://www.britannica.com/science/energy-state">
                  (https://www.britannica.com/science/energy-state)
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 DC Networks</p>
        </div>
      </div>
    </>
  );
};

{
  /* 
  
  
  
  
  
  \"\"
  
  
  
  
  */
}
