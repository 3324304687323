import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import { History } from "./components/history";
import JsonData from "./data/data.json";
import Home from "./components/home";
import { Application } from "./components/application";
import Contract from "./components/contract.jsx";

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setLandingPageData(JsonData);
    const timer = setTimeout(() => {
      setLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
    
      <Router>
        <ScrollToTop />
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/about"
            element={<About data={landingPageData.About} />}
          />
          <Route
            path="/history"
            element={<History data={landingPageData.History} />}
          />
          <Route
            path="/features"
            element={<Features data={landingPageData.Features} />}
          />
          <Route
            path="/services"
            element={<Services data={landingPageData.Services} />}
          />
          <Route
            path="/gallery"
            element={
              <Gallery
                data={landingPageData.Gallery}
                extraData={landingPageData.GalleryExtra}
                longData={landingPageData.GalleryLong}
                dataMales={landingPageData.GalleryMale}
                dataFemales={landingPageData.GalleryFemale}
                dataPuppy={landingPageData.GalleryPuppy}
              />
            }
          />
          <Route
            path="/testimonials"
            element={<Testimonials data={landingPageData.Testimonials} />}
          />
          <Route path="/team" element={<Team data={landingPageData.Team} />} />
          <Route
            path="/contact"
            element={<Contact data={landingPageData.Contact} />}
          />
          <Route
            path="/application-form"
            element={<Application data={landingPageData.Contact} />}
          />
           <Route
            path="/contract"
            element={<Contract />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
