import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center" style={{ paddingBottom: "0" }}>
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            Introducing our Stunning Monte Alto Cane Corso Male, Female &
            Puppies!
            <br />
            Scroll through our gallery to meet each individual male, female,
            puppies, and discover the perfect furry friend to join your family.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.extraData
              ? props.extraData.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>{" "}
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.longData
              ? props.longData.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>{" "}
        </div>
        <div className="row">
          <h1>Meet our Males</h1>
          <div className="portfolio-items">
            {props.dataMales
              ? props.dataMales.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
        <div className="row">
          <h1>Meet our Females</h1>
          <div className="portfolio-items">
            {props.dataFemales
              ? props.dataFemales.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
        <div className="row">
          <h1>Puppies Available Soon</h1>
          <p>
            {" "}
            Looking for a loyal, loving companion with a majestic presence? Look
            no further than our incredible litter of Monte Alto Cane Corso
            puppies! We have a variety of adorable males and females available,
            ready to bring joy and protectiveness to your life.
          </p>
          <div className="portfolio-items">
            <p>
              Monte Alto Cane Corso breeder is expecting a litter soon! These
              pups are known for being loyal, smart, and playful - perfect for
              families. Check our website for details and contact us to reserve
              your puppy.
              <br/>
              <br/>
              Litter expecting on April, 1 2024 from Akiles Di Monte Alto & Ula Aljobama Kennel
             <br/>
             <br/>
             Litter expecting April, 10 2024 Volvo Aljobama & Diva Linea Rosa
            </p>
             {props.dataPuppy
              ? props.dataPuppy.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."} 
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 DC Networks</p>
        </div>
      </div>
    </div>
  );
};
