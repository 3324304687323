import React from "react";
import pdfFile from '../../src/contract.pdf'


const Contract = () => {

  return (

    <div id="portfolio" className="text-center" style={{ paddingBottom: "0", height:"100%" }}>
      <div className="container">
        <div className="section-title">
          <h2>Contract</h2>

          <iframe src={pdfFile} frameborder="0" height="100%" width="100%"></iframe>

        </div> </div> </div>


  );
};
export default Contract;
