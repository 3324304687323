import React from "react";
import { TikTokIcon } from "./contact";

export const Application = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-7">
            <div className="row">
              <div className="section-title">
                <h2>Monte Alto Cane Corso Puppy Application</h2>
                <p>
                  Thank you for your interest in our Monte Alto Corso puppies!
                  To help us find the best possible homes for our puppies, we
                  kindly request that all potential adopters complete a brief
                  application. Completing this application does not guarantee
                  adoption, but it allows us to learn more about your lifestyle
                  and suitability for welcoming a Monte Alto Corso into your
                  family.
                  <br />
                  <b>Please note: </b> Submitting an application does not
                  obligate you to adopt a puppy. We will review all applications
                  and contact you directly to discuss your suitability and next
                  steps.
                  <br />
                  We look forward to learning more about you! <br />
                  Sincerely,
                  <br />
                  The Monte Alto Cane Corso Team.
                </p>
              </div>
              <form name="sentMessage" method="post">
                <input type="hidden" name="form-name" value="sentMessage" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="last-name"
                        name="lastName"
                        className="form-control"
                        placeholder="Last Name"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Phone"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="street"
                        name="street"
                        className="form-control"
                        placeholder="Street Address"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="city"
                        name="city"
                        className="form-control"
                        placeholder="City"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="state"
                        name="state"
                        className="form-control"
                        placeholder="State"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="number"
                        id="zip"
                        name="zip"
                        className="form-control"
                        placeholder="Zip Code"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b>
                      Are you at least 18 years of age and able to enter into a
                      legal agreement?
                    </b>
                    <div className="form-group">
                      <label>
                        <input type="radio" name="age" value="yes" />
                        Yes
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="age" value="no" />
                        No
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="puppy"
                        name="puppy"
                        className="form-control"
                        placeholder="Are you inquiring in a specific puppy currently available, or a breeding on the way?"
                        required
                      />
                      <label>
                        (If yes, provide name of puppy, or sire/dam of breeding)
                      </label>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="spouse"
                        name="spouse"
                        className="form-control"
                        placeholder="Do you have a spouse or significant other?"
                        required
                      />
                      <label>(If yes, provide name/relationship)</label>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="children"
                        name="children"
                        className="form-control"
                        placeholder="Do you have Children in your home or as frequent visitors?"
                        required
                      />
                      <label>
                        (If yes, provide Name / Age / Relationship for each one)
                      </label>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="pets"
                        name="pets"
                        className="form-control"
                        placeholder="Please list all pets that you currently own and/or care for"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b> Do you own or rent?</b>
                    <div className="form-group">
                      <label>
                        <input type="radio" name="housing" value="own" />
                        Own
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="housing" value="rent" />
                        Rent
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b> Residence Type</b>
                    <div className="form-group">
                      <label>
                        <input type="radio" name="house" value="house" />
                        House
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="house" value="townhouse" />
                        Townhouse
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="house" value="condo" />
                        Condo
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="house" value="apartment" />
                        Apartment
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="house" value="other" />
                        Other
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="residenceType"
                        name="residenceType"
                        className="form-control"
                        placeholder="How long have you lived at this residence?"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="fence"
                        name="fence"
                        className="form-control"
                        placeholder="Do you have a fenced in yard?"
                        required
                      />
                      <label>
                        (Please provide details on the fence type and height)
                      </label>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b> Will your dog live primarily: </b>
                    <div className="form-group">
                      <label>
                        <input type="radio" name="primarily" value="inside" />
                        Inside
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="primarily" value="outside" />
                        Outside
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="shelter"
                        name="shelter"
                        className="form-control"
                        placeholder="Provide details on shelter provided outside, and/or access your dog will have inside house."
                        required
                      />
                      <label>
                        (Full run of house, restricted areas of house, etc.)
                      </label>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="shelter"
                        name="shelter"
                        className="form-control"
                        placeholder="Will there be anyone home on a regular basis during the day?"
                        required
                      />
                      <label>(Provide details)</label>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b> Have you owned a dog before?</b>
                    <div className="form-group">
                      <label>
                        <input type="radio" name="pet-owner" value="Yes" />
                        Yes
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="pet-owner" value="no" />
                        No
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="previous-pets"
                        name="previous-pets"
                        className="form-control"
                        placeholder="Provide details on each of the previous dogs you have owned."
                        required
                      />
                      <label>
                        (Include each dog's breed and what happened to them or
                        why you no longer have them)
                      </label>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b> Are you interested in a:</b>
                    <div className="form-group">
                      <label>
                        <input type="radio" name="pet-type" value="male" />
                        Male
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="pet-type" value="female" />
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b> Are you interested in a:</b>
                    <div className="form-group">
                      <label>
                        <input type="radio" name="pet-for" value="companion" />
                        Companion/Pet
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="pet-for" value="=breeding" />
                        Showing / Breeding Prospect
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="color"
                        name="color"
                        className="form-control"
                        placeholder="Do you have a color preference?"
                        required
                      />
                      <label>(List up to three, in order of preference)</label>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b> Are you flexible on color preference?</b>
                    <div className="form-group">
                      <label>
                        <input type="radio" name="colorFlexible" value="yes" />
                        Yes
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="colorFlexible" value="no" />
                        NO
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b>
                      {" "}
                      What is more important? The color or temperament of the
                      puppy?
                    </b>
                    <div className="form-group">
                      <label>
                        <input
                          type="radio"
                          name="important"
                          value="temperament"
                        />
                        Temperament
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="important" value="color" />
                        Color
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="why"
                        name="why"
                        className="form-control"
                        placeholder="Please tell us why you would like to add a Monte Alto Corso to your family:"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="question"
                        name="question"
                        className="form-control"
                        placeholder="Do you have any additional questions or comments for us?:"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="veterinarian"
                        name="veterinarian"
                        className="form-control"
                        placeholder="Provide Your Current Veterinarian Information:"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="reference"
                        name="reference"
                        className="form-control"
                        placeholder="Provide a Personal Reference that is familiar with you and your animals:"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <b>
                      {" "}
                      How did you find our website? Search Engine / Website Link
                      / Referral / etc.
                    </b>
                    <div className="form-group">
                      <label>
                        <input type="radio" name="found-us" value="engine" />
                        Search Engine
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="found-us" value="website " />
                        Website Link
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="found-us" value="refferal" />
                        Refferal
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    To ensure the perfect match for both you and your future
                    furry friend, we kindly request complete and truthful
                    information in this application. This allows us to
                    understand your lifestyle and needs better, ultimately
                    placing the right puppy in a loving, forever home. Please
                    note that providing inaccurate information could delay the
                    placement process and potentially lead to the loss of your
                    deposit. We may reach out to verify details you've provided,
                    and in some cases, a home visit might be necessary. By
                    submitting this application, you confirm the accuracy of
                    your responses.
                    <div className="form-group">
                      <label>
                        <input type="radio" name="responses" value="yes" />
                        Yes
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        <input type="radio" name="responses" value="no" />
                        No
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="signature"
                        name="signature"
                        className="form-control"
                        placeholder="If you have checked YES above, type your full name in the box below to agree to the above terms:"
                        required
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-4 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                Call or Text from 9:00 AM to 9:00 PM
               {/* */}
                <span>
                  <i className="fa fa-phone"></i> English Phone
                </span>{" "}
                <a style={{color:'#fff', textDecoration:'none'}} href="tel:940 808 8702">
                {props.data ? props.data.phoneEnglish : "loading"}
                </a>
              </p>
              <p>
                <span>
                  <i className="fa fa-phone"></i> Spanish Phone
                </span>{" "}
                <a style={{color:'#fff', textDecoration:'none'}} href="tel:940 465 5010">
                {props.data ? props.data.phoneSpanish : "loading"}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <a style={{color:'#fff', textDecoration:'none'}} href="mailto:montealtocanecorso@gmail.com">
                {props.data ? props.data.email : "loading"}
                </a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.tiktok : "/"}>
                     
                    <i className="fa">  <TikTokIcon /> </i>
                    
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 DC Networks</p>
        </div>
      </div>
    </div>
  );
};
