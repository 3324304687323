import { Link } from "react-router-dom";
import { Image } from "../image";
import React from "react";

export const GalleryHome = (props) => {
  return (
    <div id="portfolio" className="text-center" style={{paddingTop:"0"}}>
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            Introducing our Stunning Monte Alto Cane Corso Male, Female &
            Puppies!
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
        <div className="row">
          <h1>Puppies Available Soon</h1>
          <div className="portfolio-items">
          <p>
          Monte Alto Cane Corso breeder is expecting a litter soon! These
              pups are known for being loyal, smart, and playful - perfect for
              families. Check our website for details and contact us to reserve
              your puppy.
              <br/>
              <br/>
             Litter expecting on April, 1 2024 from Akiles Di Monte Alto & Ula Aljobama Kennel
             <br/>
             <br/>
             Litter expecting April, 10 2024 Volvo Aljobama & Diva Linea Rosa
            </p>
            {props.dataPuppy
              ? props.dataPuppy.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
      <div style={{textAlign: "center", marginTop:"2em"}}>
      <Link
        to="/gallery"
        className="btn btn-custom btn-lg"
      >
        Learn More
      </Link>{" "}
      </div>
    </div>
  );
};
