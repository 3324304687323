import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
            At Monte Alto Cane Corso, we believe exceptional dogs deserve an
            exceptional team. Our dedicated staff is the heart and soul of our
            operation, bringing a wealth of experience and unwavering passion to
            every aspect of breeding and caring for these magnificent animals.
            Each member plays a vital role, from our breeders with a keen eye
            for genetics and temperament, to our veterinary technicians who
            ensure the health and well-being of our puppies. Our knowledgeable
            customer service representatives are here to answer your questions
            and guide you through the process of finding the perfect match for
            your family. United by a shared love for Cane Corsos, our team is
            committed to upholding the breed's standards and ensuring a smooth
            transition for both puppies and their forever homes.
          </p>
        </div>
        <div id="row" >
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
