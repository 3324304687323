import React, { useState, useEffect } from "react";
import { Header } from "./header";
import { Features } from "./features";
import { Services } from "./services";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import JsonData from "../data/data.json";
import "../App.css";
import { HistoryHome } from "./Home-Components/history-home";
import { GalleryHome } from "./Home-Components/gallery-home";
import { AboutHome } from "./Home-Components/about-home";
import { HomeServices } from "./Home-Components/services-home";
import { TestimonialsHome } from "./Home-Components/testimonial-home";

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <AboutHome data={landingPageData.AboutHome} />
      <HomeServices data={landingPageData.HomeServices} />
      <HistoryHome data={landingPageData.History} />
      <GalleryHome
        data={landingPageData.GalleryHome}
        dataPuppy={landingPageData.GalleryPuppy}
      />
      <TestimonialsHome data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default Home;
