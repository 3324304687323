import React, { useState } from "react";
import { Link } from "react-router-dom";


export const Navigation = (props) => {

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <Link className="navbar-brand" to="/">
            Monte Alto
            <br /> Cane Corso
          </Link>{" "}
        </div>

      <div
          className="collapse navbar-collapse" 
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link to="/" >Home</Link>
            </li>
            <li>
              <Link to="/about" >About</Link>
            </li>
            <li>
              <Link to="/history" >History</Link>
            </li>
            <li>
              <Link to="/services" >Services</Link>
            </li>
            <li>
              <Link to="/gallery" >Gallery</Link>
            </li>
            <li>
              <Link to="/testimonials" >Testimonials</Link>
            </li>
            {/* <li>
              <Link to="/team">Team</Link>
            </li> */}
            <li>
              <Link to="/contact" >Contact</Link>
            </li>
            <li>
              <Link to="/contract" >Contract</Link>
            </li>
            <li>
              <Link to="/application-form" >Apply Now</Link>
            </li>
          </ul>
        </div> 
      </div>
    </nav>
  );
};
