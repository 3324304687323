import React from "react";
import { Link } from "react-router-dom";

export const HistoryHome = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img
              src="img/canes/canehistory.jpg"
              className="img-responsive"
              alt=""
            />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Cane Corso History</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{textAlign: "center"}}>
      <Link
        to="/history"
        className="btn btn-custom btn-lg"
      >
        Learn More
      </Link>{" "}
      </div>
    </div>
  );
};
