import React from "react";
import { Link } from "react-router-dom";

export const AboutHome = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt=""  style={{ margin:"0 auto"}}/>{" "}
          </div>
          <div className="col-xs-12 col-md-6" style={{ margin:"7em auto"}}>
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{textAlign: "center", marginTop:"2em"}}>
      <Link
        to="/about"
        className="btn btn-custom btn-lg"
      >
        Learn More
      </Link>{" "}
      </div>
    </div>
  );
};
